/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createTestAccount = /* GraphQL */ `mutation CreateTestAccount(
  $input: CreateTestAccountInput!
  $condition: ModelTestAccountConditionInput
) {
  createTestAccount(input: $input, condition: $condition) {
    id
    connectId
    msisdn
    name
    hasSafe
    hasMPort
    hasQRCode
    hasEsim
    canOrderSubscription
    hasIdTheftInsurance
    hasSvindelforsikring
    hasCancellableData
    hasSecretNumber
    subscriptionType
    marketType
    postpaid
    prepaid
    isHybrid
    subscriptionFamily
    handsets {
      simCard {
        msisdn
        type
        network
        name
        isEsim
        isGolden
        barringStatus {
          barred
          __typename
        }
        __typename
      }
      handset {
        vendor
        model
        image {
          url
          __typename
        }
        agreement {
          fromDate
          __typename
        }
        __typename
      }
      __typename
    }
    roles
    invoices {
      invoices {
        invoiceId
        invoiceType
        status
        __typename
      }
      creditMemos {
        invoiceId
        status
        __typename
      }
      vippsReceipts {
        status
        __typename
      }
      __typename
    }
    discountTypes
    promotedFeature
    subscriptionSource
    numberOfMobileSubscriptions
    numberOfFixedSubscriptions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTestAccountMutationVariables,
  APITypes.CreateTestAccountMutation
>;
export const updateTestAccount = /* GraphQL */ `mutation UpdateTestAccount(
  $input: UpdateTestAccountInput!
  $condition: ModelTestAccountConditionInput
) {
  updateTestAccount(input: $input, condition: $condition) {
    id
    connectId
    msisdn
    name
    hasSafe
    hasMPort
    hasQRCode
    hasEsim
    canOrderSubscription
    hasIdTheftInsurance
    hasSvindelforsikring
    hasCancellableData
    hasSecretNumber
    subscriptionType
    marketType
    postpaid
    prepaid
    isHybrid
    subscriptionFamily
    handsets {
      simCard {
        msisdn
        type
        network
        name
        isEsim
        isGolden
        barringStatus {
          barred
          __typename
        }
        __typename
      }
      handset {
        vendor
        model
        image {
          url
          __typename
        }
        agreement {
          fromDate
          __typename
        }
        __typename
      }
      __typename
    }
    roles
    invoices {
      invoices {
        invoiceId
        invoiceType
        status
        __typename
      }
      creditMemos {
        invoiceId
        status
        __typename
      }
      vippsReceipts {
        status
        __typename
      }
      __typename
    }
    discountTypes
    promotedFeature
    subscriptionSource
    numberOfMobileSubscriptions
    numberOfFixedSubscriptions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTestAccountMutationVariables,
  APITypes.UpdateTestAccountMutation
>;
export const deleteTestAccount = /* GraphQL */ `mutation DeleteTestAccount(
  $input: DeleteTestAccountInput!
  $condition: ModelTestAccountConditionInput
) {
  deleteTestAccount(input: $input, condition: $condition) {
    id
    connectId
    msisdn
    name
    hasSafe
    hasMPort
    hasQRCode
    hasEsim
    canOrderSubscription
    hasIdTheftInsurance
    hasSvindelforsikring
    hasCancellableData
    hasSecretNumber
    subscriptionType
    marketType
    postpaid
    prepaid
    isHybrid
    subscriptionFamily
    handsets {
      simCard {
        msisdn
        type
        network
        name
        isEsim
        isGolden
        barringStatus {
          barred
          __typename
        }
        __typename
      }
      handset {
        vendor
        model
        image {
          url
          __typename
        }
        agreement {
          fromDate
          __typename
        }
        __typename
      }
      __typename
    }
    roles
    invoices {
      invoices {
        invoiceId
        invoiceType
        status
        __typename
      }
      creditMemos {
        invoiceId
        status
        __typename
      }
      vippsReceipts {
        status
        __typename
      }
      __typename
    }
    discountTypes
    promotedFeature
    subscriptionSource
    numberOfMobileSubscriptions
    numberOfFixedSubscriptions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTestAccountMutationVariables,
  APITypes.DeleteTestAccountMutation
>;
export const createConnectIdLogin = /* GraphQL */ `mutation CreateConnectIdLogin(
  $input: CreateConnectIdLoginInput!
  $condition: ModelConnectIdLoginConditionInput
) {
  createConnectIdLogin(input: $input, condition: $condition) {
    id
    connectId
    refreshToken
    accessToken
    pkce
    scope
    expiryDate
    state
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateConnectIdLoginMutationVariables,
  APITypes.CreateConnectIdLoginMutation
>;
export const updateConnectIdLogin = /* GraphQL */ `mutation UpdateConnectIdLogin(
  $input: UpdateConnectIdLoginInput!
  $condition: ModelConnectIdLoginConditionInput
) {
  updateConnectIdLogin(input: $input, condition: $condition) {
    id
    connectId
    refreshToken
    accessToken
    pkce
    scope
    expiryDate
    state
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateConnectIdLoginMutationVariables,
  APITypes.UpdateConnectIdLoginMutation
>;
export const deleteConnectIdLogin = /* GraphQL */ `mutation DeleteConnectIdLogin(
  $input: DeleteConnectIdLoginInput!
  $condition: ModelConnectIdLoginConditionInput
) {
  deleteConnectIdLogin(input: $input, condition: $condition) {
    id
    connectId
    refreshToken
    accessToken
    pkce
    scope
    expiryDate
    state
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteConnectIdLoginMutationVariables,
  APITypes.DeleteConnectIdLoginMutation
>;
