import { z } from "zod";

export type FrontendpagesResponse = z.infer<typeof FrontendpagesResponseParser>;
export const FrontendpagesResponseParser = z.array(
  z.discriminatedUnion("status", [
    z.object({
      status: z.literal("error"),
      error: z.object({ errorCode: z.number(), description: z.string() }),
    }),
    z.object({
      status: z.literal("success"),
      data: z.object({
        msisdn: z.string(),
        name: z.string(),
        hasFamilyBonus: z.boolean(),
        hasSecretNumber: z.boolean(),
        subscriptionType: z.string(),
        marketType: z.string(),
        postpaid: z.boolean(),
        prepaid: z.boolean(),
        isHybrid: z.boolean(),
        hasGoodies: z.boolean(),
        subscriptionFamily: z.string(),
        mainSim: z.optional(
          z.object({ msisdn: z.string(), barred: z.boolean() })
        ),
        handsets: z.array(
          z.object({
            handset: z.optional(
              z.object({
                vendor: z.optional(z.string()),
                model: z.optional(z.string()),
                image: z.optional(z.object({ url: z.optional(z.string()) })),
              })
            ),
            simCard: z.optional(
              z.object({
                msisdn: z.optional(z.string()),
                type: z.optional(z.string()),
                network: z.optional(z.string()),
                name: z.optional(z.string()),
                isEsim: z.optional(z.boolean()),
                isGolden: z.optional(z.boolean()),
                barringStatus: z.optional(
                  z.object({
                    barred: z.optional(z.boolean()),
                  })
                ),
              })
            ),
          })
        ),
        consumerFamily: z.object({ hasConsumerFamilyDiscount: z.boolean() }),
        roles: z.array(z.enum(["USER", "OWNER", "PAYER"])),
        user: z.object({ isOrganization: z.boolean() }),
        roamingStatus: z.object({
          status: z.string(),
          countryIso: z.string(),
          countryName: z.string(),
          technicalZoneName: z.string(),
          isRoamLikeHomeEnabled: z.boolean(),
          roamingText: z.string(),
        }),
        totalCost: z.object({ cost: z.number() }),
        dataUsages: z.array(
          z.object({
            type: z.optional(
              z.enum(["OVERVIEW", "OVERVIEW_NATIONAL", "OVERVIEW_ROAMING"])
            ),
            assigned: z.number().int().default(0),
            consumed: z.number().int().default(0),
            usageUnit: z.string(),
            counterType: z.string(),
            automaticallyRenewable: z.boolean(),
            action: z.string(),
            hasFocus: z.boolean(),
            dataCircleType: z.string(),
            actionInfo: z.optional(z.object({ action: z.string() })),
          })
        ),
      }),
    }),
  ])
);

export type UserResponse = z.infer<typeof UserResponseParser>;
export const UserResponseParser = z.object({
  kurtId: z.number(),
  userId: z.string(),
  firstName: z.optional(z.string()),
  middleName: z.optional(z.string()),
  lastName: z.optional(z.string()),
  birthDate: z.optional(z.string()),
  organizationName: z.optional(z.string()),
  organizationNumber: z.optional(z.string()),
  address: z.optional(z.object({
    streetName: z.optional(z.string()),
    streetNumber: z.optional(z.string()),
    entranceCode: z.optional(z.string()),
    postalCode: z.optional(z.string()),
    postalPlace: z.optional(z.string()),
    country: z.string(),
  })),
  postalAddress: z.optional(z.object({
    streetName: z.optional(z.string()),
    streetNumber: z.optional(z.string()),
    entranceCode: z.optional(z.string()),
    postalCode: z.optional(z.string()),
    postalPlace: z.optional(z.string()),
    country: z.string(),
  })),
  isOrganization: z.boolean()
})

// not complete
export type PricesResponse = z.infer<typeof PricesResponseParser>;
export const PricesResponseParser = z.object({
  prices: z.array(z.object({})),
  bindings: z.array(z.object({})),
  campaigns: z.array(z.object({
    productId: z.number(),
    campaignName: z.string(),
    prices: z.array(z.object({})),
    campaignExpireDate: z.optional(z.string()),
    texts: z.array(z.object({})),
    discountType: z.string(),
    discountCategory: z.string(),
    classifications: z.array(z.object({classificationId: z.optional(z.string())})),
    isSubscribed: z.boolean(),
  })),
  isVATIncluded: z.boolean(),
})

// not complete
export type MobileSubscriptionResponse = z.infer<typeof MobileSubscriptionResponseParser>;
export const MobileSubscriptionResponseParser = z.object({
  msisdn: z.string(),
  promotedFeature: z.optional(z.object({
    header: z.string(),
  }))
})

// not complete
export type FixedSubscriptionResponse = z.infer<typeof FixedSubscriptionResponseParser>;
export const FixedSubscriptionResponseParser = z.object({
  subscriptions: z.optional(z.array(z.object({
    type: z.string(),
    valueChain: z.optional(z.string()),
    id: z.string(),
    roles: z.optional(z.array(z.string())),
    subscriptionName: z.string(),
  }))),
  suppressions: z.optional(z.array(z.object({}))),
})