import { Flex, Button, View, Card } from '@aws-amplify/ui-react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Logo } from './Logo';
import { Auth } from 'aws-amplify';
import React, { useCallback } from 'react';
import { AuthenticatedUserAttributes } from '../App';

export const TopBar: React.FC<{ user?: AuthenticatedUserAttributes }> = (user) => {
  const navigate = useNavigate();
  const onPressMenuButton = (name: string) => {
    switch (name) {
      case 'accounts':
        navigate('/accounts');
        break;
      case 'tools':
        navigate('/tools');
        break;
      case 'testconfig':
        navigate('/testconfig');
        break;
      case 'login':
        navigate('/login');
        break;
      case 'home':
        navigate('/');
        break;
      default:
        navigate('/');
        break;
    }
  };

  const logOut = useCallback(async () => {
    navigate('/');
    await Auth.signOut();
  }, [navigate]);

  return (
    <View>
      <Card
        flex={1}
        position={'sticky'}
        top={0}
        left={0}
        right={0}
        columnStart="1"
        columnEnd="-1"
        style={{ marginBottom: '1rem' }}
        boxShadow={'0 3px 2px -2px rgba(0, 0, 0, 0.2)'}>
        <Flex as="nav" justifyContent="space-between">
          <Logo title="Management" />
          <Flex>
            <Button
              variation="link"
              onClick={() => {
                onPressMenuButton('testconfig');
              }}>
              ⚙️ Testconfig
            </Button>
            {user.user && (
              <Button
                variation="link"
                onClick={() => {
                  onPressMenuButton('tools');
                }}>
                🛠️ Tools
              </Button>
            )}
            {user.user && (
              <Button
                variation="link"
                onClick={() => {
                  onPressMenuButton('accounts');
                }}>
                🧪 Accounts
              </Button>
            )}
            {user.user ? (
              <Button variation="link" onClick={() => logOut()}>
                👋 Logout
              </Button>
            ) : (
              <Button variation="link" onClick={() => onPressMenuButton('login')}>
                👉 Login
              </Button>
            )}
          </Flex>
        </Flex>
      </Card>
      <main style={{ padding: '1rem' }}>
        <Outlet />
      </main>
    </View>
  );
};
