import GraphQLAPI from '@aws-amplify/api-graphql';
import React, { useCallback, useEffect, useMemo, useState, useTransition } from 'react';
import { useTable } from 'react-table';
import RelativeTime from 'react-time-ago';
import { ListTestAccountsQuery } from '../../API';
import { listTestAccounts } from '../../graphql/queries';
import {
  Badge,
  Button,
  CheckboxField,
  Expander,
  ExpanderItem,
  Flex,
  Loader,
  SelectField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@aws-amplify/ui-react';

export const TestAccountsReactTableView: React.FC<{ highlightMsisdn?: string }> = ({ highlightMsisdn }) => {
  const [data, setData] = useState<TestAccountFrontEnd>([]);
  const fetchTestaccounts = async () => {
    return (await GraphQLAPI.graphql({ query: listTestAccounts, variables: { limit: 1000 } })) as {
      data: ListTestAccountsQuery;
    };
  };
  const [apiIsLoading, setApiIsLoading] = useState(false);
  const refreshAllAccounts = useCallback(() => {
    setApiIsLoading(true);
    const func = async () => {
      try {
        const result = await fetchTestaccounts();
        setData(mapData(result.data.listTestAccounts?.items ?? []));
      } catch (err) {
        console.log(err);
      } finally {
        setApiIsLoading(false);
      }
    };
    func();
  }, []);

  useEffect(() => {
    refreshAllAccounts();
  }, [refreshAllAccounts]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Subscription',
        accessor: 'subscription',
      },
      {
        Header: 'Connect ID',
        accessor: 'connectId',
      },
      {
        Header: 'Msisdn',
        accessor: 'msisdn',
      },
      {
        Header: 'Roles',
        accessor: 'roles',
        //@ts-ignore
        Cell: ({ cell: { value } }) => {
          const valueTyped = value ? (value as string[]) : [];
          return (
            <Flex>
              {valueTyped.sort().map((aVal) => (
                <Badge>{aVal}</Badge>
              ))}
            </Flex>
          );
        },
      },
      {
        Header: 'Family',
        accessor: 'family',
      },
      {
        Header: 'Extras',
        accessor: 'extras',
        //@ts-ignore
        Cell: ({ cell: { value } }) => {
          const valueTyped = value as string[];
          return (
            <Flex>
              {valueTyped.map((aVal) => (
                <Badge>{aVal}</Badge>
              ))}
            </Flex>
          );
        },
      },
      {
        Header: 'Last Change',
        accessor: 'updated',
        //@ts-ignore
        Cell: ({ cell: { value } }) => {
          return (
            <Badge>
              <RelativeTime tooltip date={new Date(value)} />
            </Badge>
          );
        },
      },
    ];
  }, []);
  const [typeFilter, setTypeFilter] = useState('');
  const [subscriptionFilter, setSubscriptionFilter] = useState('');
  const [familyFilter, setFamilyFilter] = useState('');
  const [connectIdFilter, setConnectIdFilter] = useState('');
  const [msisdnFilter, setMsisdnFilter] = useState('');
  const [extraFilter, setExtraFilter] = useState<Array<string>>([]);
  const [notAnExtraFilter, setNotAnExtraFilter] = useState<Array<string>>([]);
  const [roleFilter, setRoleFilter] = useState<Array<string>>([]);
  const [notARoleFilter, setNotARoleFilter] = useState<Array<string>>([]);
  const [filter, setFilter] = useState<Filter>({
    type: '',
    subscription: '',
    family: '',
    extra: [],
    notAnExtra: [],
    role: [],
    notARole: [],
    msisdn: '',
    connectId: '',
  });
  useEffect(() => {
    startTransition(() => {
      setFilter({
        type: typeFilter,
        subscription: subscriptionFilter,
        family: familyFilter,
        extra: extraFilter,
        notAnExtra: notAnExtraFilter,
        role: roleFilter,
        notARole: notARoleFilter,
        msisdn: msisdnFilter,
        connectId: connectIdFilter,
      });
    });
  }, [
    subscriptionFilter,
    familyFilter,
    extraFilter,
    roleFilter,
    notARoleFilter,
    msisdnFilter,
    connectIdFilter,
    typeFilter,
    notAnExtraFilter,
  ]);
  const [isPending, startTransition] = useTransition();
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    const handleScrollButtonVisibility = () => {
      window.scrollY > 250 ? setShowButton(true) : setShowButton(false);
    };
    window.addEventListener('scroll', handleScrollButtonVisibility);
    return () => {
      window.removeEventListener('scroll', handleScrollButtonVisibility);
    };
  }, []);
  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <>
      <Expander type="single" isCollapsible={true} defaultValue={['filter']}>
        <ExpanderItem title="Filter" value="filter">
          <Flex direction="row">
            <Flex direction="column">
              <SelectField
                value={subscriptionFilter}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  setSubscriptionFilter(event.target.value);
                }}
                label="Subscription"
                options={[''].concat(
                  data
                    .flatMap((aDate) => aDate.subscription)
                    .filter(nullFilter)
                    .filter(onlyUnique)
                    .sort(sortAlphabetical),
                )}></SelectField>
              <SelectField
                value={familyFilter}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  setFamilyFilter(event.target.value);
                }}
                label="Family"
                options={[''].concat(
                  data
                    .flatMap((aDate) => aDate.family)
                    .filter(nullFilter)
                    .filter(onlyUnique)
                    .sort(sortAlphabetical)
                    .sort(),
                )}></SelectField>
              <SelectField
                value={typeFilter}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  setTypeFilter(event.target.value);
                }}
                label="Type"
                options={[''].concat(
                  data
                    .flatMap((aDate) => aDate.type)
                    .filter(nullFilter)
                    .filter(onlyUnique)
                    .sort(sortAlphabetical)
                    .sort(),
                )}></SelectField>
            </Flex>
            <Flex direction="column">
              {data
                .flatMap((aDate) => aDate.extras)
                .flat()
                .filter(nullFilter)
                .filter(onlyUnique)
                .sort(sortAlphabetical)
                .map((anExtra) => {
                  return (
                    <CheckboxField
                      checked={extraFilter.includes(anExtra)}
                      onChange={() =>
                        setExtraFilter((preVal) => {
                          if (preVal.includes(anExtra)) {
                            return preVal.filter((anotherExtra) => anotherExtra !== anExtra);
                          }
                          return preVal.concat(anExtra);
                        })
                      }
                      label={anExtra}
                      value={''}
                      name={anExtra}
                    />
                  );
                })}
            </Flex>
            <Flex direction="column">
              {data
                .flatMap((aDate) => aDate.extras)
                .flat()
                .filter(nullFilter)
                .filter(onlyUnique)
                .sort(sortAlphabetical)
                .map((anExtra) => {
                  return (
                    <CheckboxField
                      checked={notAnExtraFilter.includes(anExtra)}
                      onChange={() =>
                        setNotAnExtraFilter((preVal) => {
                          if (preVal.includes(anExtra)) {
                            return preVal.filter((anotherExtra) => anotherExtra !== anExtra);
                          }
                          return preVal.concat(anExtra);
                        })
                      }
                      label={'Not ' + anExtra}
                      value={''}
                      name={'not_' + anExtra}
                    />
                  );
                })}
            </Flex>
            <Flex direction="column">
              {data
                .flatMap((aDate) => aDate.roles)
                .flat()
                .filter(nullFilter)
                .filter(onlyUnique)
                .sort(sortAlphabetical)
                .map((aRole) => {
                  return (
                    <CheckboxField
                      checked={roleFilter.includes(aRole)}
                      onChange={() =>
                        setRoleFilter((preVal) => {
                          if (preVal.includes(aRole)) {
                            return preVal.filter((anotherRole) => anotherRole !== aRole);
                          }
                          return preVal.concat(aRole);
                        })
                      }
                      label={aRole}
                      value={''}
                      name={aRole}
                    />
                  );
                })}
            </Flex>
            <Flex direction="column">
              {data
                .flatMap((aDate) => aDate.roles)
                .flat()
                .filter(nullFilter)
                .filter(onlyUnique)
                .sort(sortAlphabetical)
                .map((aRole) => {
                  return (
                    <CheckboxField
                      checked={notARoleFilter.includes(aRole)}
                      onChange={() =>
                        setNotARoleFilter((preVal) => {
                          if (preVal.includes(aRole)) {
                            return preVal.filter((anotherRole) => anotherRole !== aRole);
                          }
                          return preVal.concat(aRole);
                        })
                      }
                      label={'Not ' + aRole}
                      value={''}
                      name={'not_' + aRole}
                    />
                  );
                })}
            </Flex>
            <Flex direction="column">
              <TextField label="msisdn" value={msisdnFilter} onChange={(e) => setMsisdnFilter(e.currentTarget.value)} />
              <TextField
                label="connectId"
                value={connectIdFilter}
                onChange={(e) => setConnectIdFilter(e.currentTarget.value)}
              />
            </Flex>
          </Flex>
        </ExpanderItem>
      </Expander>
      <MyTable isLoading={isPending || apiIsLoading} inputColumns={columns} data={data} filter={filter} />
      {showButton ? (
        <Button
          style={{
            position: 'fixed',
            bottom: 20,
            right: 20,
            height: 70,
            width: 70,
            borderRadius: 35,
            backgroundColor: '#33D4FA',
            zIndex: 2,
          }}
          fontSize={28}
          onClick={scrollToTop}>
          ⏫
        </Button>
      ) : null}
    </>
  );
};

function sortAlphabetical(a: string, b: string): number {
  return a < b ? -1 : a > b ? 1 : 0;
}

function nullFilter<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}
function onlyUnique<T>(value: T, index: number, array: Array<T>) {
  return array.indexOf(value) === index;
}

type Filter = {
  type: string;
  subscription: string;
  family: string;
  extra: string[];
  notAnExtra: string[];
  role: string[];
  notARole: string[];
  msisdn: string;
  connectId: string;
};

type TestAccountFrontEnd = ReturnType<typeof mapData>;
function mapData(data: NonNullable<ListTestAccountsQuery['listTestAccounts']>['items']) {
  return data
    .filter(nullFilter)
    .sort((l, r) => {
      if (l.connectId === r.connectId) {
        if (l.subscriptionSource !== r.subscriptionSource) {
          if (l.subscriptionSource === 'ACCOUNT') {
            return -1;
          } else if (r.subscriptionSource === 'ACCOUNT') {
            return +1;
          } else if (l.subscriptionSource === 'MOBILE') {
            return -1;
          } else if (r.subscriptionSource === 'MOBILE') {
            return +1;
          }
        }
      }
      return parseInt(l.connectId) - parseInt(r.connectId);
    })
    .map((aDate) => {
      const twinSim =
        aDate.handsets?.filter(
          (aHandset) => aHandset?.simCard && aHandset.simCard.type?.toUpperCase().includes('TWIN'),
        ) ?? [];
      const dataSim =
        aDate.handsets?.filter(
          (aHandset) => aHandset?.simCard && aHandset.simCard.type?.toUpperCase().includes('DATA'),
        ) ?? [];
      return {
        type:
          aDate.subscriptionSource === 'ACCOUNT'
            ? 'ACC'
            : aDate.subscriptionSource === 'FIXED'
            ? 'FIXED'
            : aDate.subscriptionSource === 'MOBILE'
            ? 'MOBILE'
            : undefined,
        subscription: aDate.name,
        msisdn: aDate.msisdn,
        connectId: aDate.connectId,
        roles: aDate.roles,
        family: aDate.subscriptionFamily,
        extras: [
          aDate.handsets?.some((handset) => handset?.handset?.agreement) ? 'SWAP' : null,
          aDate.subscriptionSource === 'ACCOUNT' && aDate.numberOfMobileSubscriptions !== -1
            ? `Subs (${aDate.numberOfMobileSubscriptions?.toString()} mobile)`
            : null,
          aDate.subscriptionSource === 'ACCOUNT' && aDate.numberOfFixedSubscriptions !== -1
            ? `Subs (${aDate.numberOfFixedSubscriptions?.toString()} fixed)`
            : null,
          twinSim.length > 0 ? 'SIM (' + twinSim.length.toString() + ' twin)' : null,
          dataSim.length > 0 ? 'SIM (' + dataSim.length.toString() + ' data)' : null,
          aDate.hasSafe && aDate.subscriptionSource === 'ACCOUNT' ? 'SAFE' : null,
          aDate.hasIdTheftInsurance && aDate.subscriptionSource === 'ACCOUNT' ? 'ID Theft' : null,
          aDate.hasSvindelforsikring && aDate.subscriptionSource === 'ACCOUNT' ? 'Svindelforsikring' : null,
          aDate.hasSecretNumber ? 'Secret Number' : null,
          ...(aDate.discountTypes?.filter(nullFilter) ?? []),
          aDate.promotedFeature ? aDate.promotedFeature : null,
          aDate.hasCancellableData ? 'Cancellable Datapackage' : null,
          aDate.canOrderSubscription ? 'Can order' : null,
          aDate.hasMPort ? 'MPort' : null,
          aDate.hasEsim ? 'Esim' : null,
          aDate.hasQRCode ? 'QRcode' : null,
          aDate.invoices?.invoices?.some((invoice) => invoice?.status === 'OPEN') ? 'Invoice (open)' : null,
          aDate.invoices?.invoices?.some((invoice) => invoice?.status === 'PARTIALLY_PAID')
            ? 'Invoice (partly paid)'
            : null,
          aDate.invoices?.invoices?.some((invoice) => invoice?.status === 'DEBT_COLLECTION_OPEN')
            ? 'Invoice (dept coll.)'
            : null,
          (aDate.invoices?.creditMemos?.length ?? 0) > 0 ? 'Credit memo' : null,
          (aDate.invoices?.vippsReceipts?.length ?? 0) > 0 ? 'Vipps receipt' : null,
        ].filter(nullFilter),
        updated: aDate.updatedAt,
      };
    });
}

const MyTable: React.FC<{
  isLoading: boolean;
  inputColumns: any[];
  data: TestAccountFrontEnd;
  filter: Filter;
}> = React.memo(({ isLoading, inputColumns, data, filter }) => {
  const filteredData = data.filter(nullFilter).filter((aDate) => {
    return (
      (!filter.type || aDate.type === filter.type) &&
      (!filter.family || aDate.family === filter.family) &&
      (!filter.subscription || aDate.subscription === filter.subscription) &&
      filter.extra.every((anExtra) => aDate.extras.includes(anExtra)) &&
      filter.notAnExtra.every((anExtra) => !aDate.extras.includes(anExtra)) &&
      filter.role.every((aRole) => aDate.roles?.includes(aRole)) &&
      filter.notARole.every((aRole) => !aDate.roles?.includes(aRole)) &&
      (!filter.msisdn || aDate.msisdn.includes(filter.msisdn)) &&
      (!filter.connectId || aDate.connectId.includes(filter.connectId))
    );
  });
  const {
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({
    // @ts-ignore
    columns: inputColumns,
    data: filteredData,
  });
  return (
    <Table variation="striped" highlightOnHover={true}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
            {headerGroup.headers.map((column) => (
              <TableCell as="th" key={column.id}>
                {column.render('Header')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {isLoading ? (
          <Loader size="large" />
        ) : (
          rows.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()} key={row.id}>
                {row.cells.map((cell) => {
                  return <TableCell>{cell.render('Cell')}</TableCell>;
                })}
              </TableRow>
            );
          })
        )}
      </TableBody>
    </Table>
  );
});
