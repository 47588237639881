const endpoint =
  process.env.REACT_APP_ENV_E2E_LOGIN_ENDPOINT ??
  "https://api.mitt-telenor.univex.no/loginUsingSelenium";
const refreshEndpoint = "https://api.mitt-telenor.univex.no/refreshAccount"
export const loginTestAccount = async (msisdn: string, jwt: string) => {
  const result = await fetch(`${endpoint}?msisdn=${msisdn}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  const json = await result.json();
  return {
    id_token: json["id_token"] as string | undefined,
    access_token: json["access_token"] as string | undefined,
    refresh_token: json["refresh_token"] as string | undefined,
    scope: json["scope"] as string | undefined,
    pkce: json["pkce"] as string | undefined,
    expire_date: (json["expires_in"] + new Date().getTime() / 1000.0) as
      | number
      | undefined,
  };
};

export const refreshAccountAsync = async (connectId: string, jwt: string) => {
  console.log('refresh', connectId)
  const result = await fetch(`${refreshEndpoint}?connectId=${connectId}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
      InvocationType: 'Event'
    },
  });
  console.log(connectId, result)
  return {
    status: result.status
  };
};