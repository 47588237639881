import { Flex, Heading, Text } from '@aws-amplify/ui-react';
import { AuthenticatedUserAttributes } from '../App';

const HomePage: React.FC<{ user?: AuthenticatedUserAttributes }> = (user) => {
  const email = user.user?.email ?? '👻';
  const text = user.user
    ? 'Use the menus above to manage, here will come a dashboard'
    : 'Log in to manage, or go straight to test config to activate test config on your device.';
  return (
    <Flex direction={'column'}>
      <Heading level={3}>{`Hello ${email}!`}</Heading>
      <Text>{text}</Text>
    </Flex>
  );
};

export default HomePage;
