/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getTestAccount = /* GraphQL */ `query GetTestAccount($id: ID!) {
  getTestAccount(id: $id) {
    id
    connectId
    msisdn
    name
    hasSafe
    hasMPort
    hasQRCode
    hasEsim
    canOrderSubscription
    hasIdTheftInsurance
    hasSvindelforsikring
    hasCancellableData
    hasSecretNumber
    subscriptionType
    marketType
    postpaid
    prepaid
    isHybrid
    subscriptionFamily
    handsets {
      simCard {
        msisdn
        type
        network
        name
        isEsim
        isGolden
        barringStatus {
          barred
          __typename
        }
        __typename
      }
      handset {
        vendor
        model
        image {
          url
          __typename
        }
        agreement {
          fromDate
          __typename
        }
        __typename
      }
      __typename
    }
    roles
    invoices {
      invoices {
        invoiceId
        invoiceType
        status
        __typename
      }
      creditMemos {
        invoiceId
        status
        __typename
      }
      vippsReceipts {
        status
        __typename
      }
      __typename
    }
    discountTypes
    promotedFeature
    subscriptionSource
    numberOfMobileSubscriptions
    numberOfFixedSubscriptions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTestAccountQueryVariables,
  APITypes.GetTestAccountQuery
>;
export const listTestAccounts = /* GraphQL */ `query ListTestAccounts(
  $filter: ModelTestAccountFilterInput
  $limit: Int
  $nextToken: String
) {
  listTestAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      connectId
      msisdn
      name
      hasSafe
      hasMPort
      hasQRCode
      hasEsim
      canOrderSubscription
      hasIdTheftInsurance
      hasSvindelforsikring
      hasCancellableData
      hasSecretNumber
      subscriptionType
      marketType
      postpaid
      prepaid
      isHybrid
      subscriptionFamily
      handsets {
        simCard {
          msisdn
          type
          network
          name
          isEsim
          isGolden
          barringStatus {
            barred
            __typename
          }
          __typename
        }
        handset {
          vendor
          model
          image {
            url
            __typename
          }
          agreement {
            fromDate
            __typename
          }
          __typename
        }
        __typename
      }
      roles
      invoices {
        invoices {
          invoiceId
          invoiceType
          status
          __typename
        }
        creditMemos {
          invoiceId
          status
          __typename
        }
        vippsReceipts {
          status
          __typename
        }
        __typename
      }
      discountTypes
      promotedFeature
      subscriptionSource
      numberOfMobileSubscriptions
      numberOfFixedSubscriptions
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTestAccountsQueryVariables,
  APITypes.ListTestAccountsQuery
>;
export const getConnectIdLogin = /* GraphQL */ `query GetConnectIdLogin($id: ID!) {
  getConnectIdLogin(id: $id) {
    id
    connectId
    refreshToken
    accessToken
    pkce
    scope
    expiryDate
    state
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetConnectIdLoginQueryVariables,
  APITypes.GetConnectIdLoginQuery
>;
export const listConnectIdLogins = /* GraphQL */ `query ListConnectIdLogins(
  $filter: ModelConnectIdLoginFilterInput
  $limit: Int
  $nextToken: String
) {
  listConnectIdLogins(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      connectId
      refreshToken
      accessToken
      pkce
      scope
      expiryDate
      state
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListConnectIdLoginsQueryVariables,
  APITypes.ListConnectIdLoginsQuery
>;
