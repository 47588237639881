import { TabItem, Tabs, withAuthenticator } from '@aws-amplify/ui-react';
import { ManageTestAccounts } from './ManageTestAccounts';
import { useParams } from 'react-router-dom';
import { TestAccountsReactTableView } from './TestAccountsReactTableView';

function TestAccountsPage() {
  const params = useParams();
  return (
    <Tabs>
      <TabItem title="Overview">
        <TestAccountsReactTableView highlightMsisdn={params.msisdn} />
      </TabItem>
      <TabItem title="⚙️ Manage">
        <ManageTestAccounts />
      </TabItem>
    </Tabs>
  );
}

export default withAuthenticator(TestAccountsPage);
