import '@aws-amplify/ui-react/styles.css';
import config from './aws-exports';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { TopBar } from './Components/TopBar';
import { ThemeProvider } from '@aws-amplify/ui-react';
import { TelenorApiClient } from './TelenorApiClient/TelenorClient';
import { useEffect, useState } from 'react';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import { Route, Routes, useNavigate } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import TestAccountsPage from './Pages/TestAccounts/TestAccountsPage';
import FeatureFlagsPage from './Pages/FeatureFlagsPage';
import ToolsPage from './Pages/ToolsPage';
import TestConfigPage from './Pages/TestConfigPage';
import SignInPage from './Pages/SignInPage';

TimeAgo.addDefaultLocale(en);
Amplify.configure(config);

const allClients: TelenorApiClient[] = [];
export const useApiClients = () => {
  const [clients, setClients] = useState(allClients);
  const createNewClient = (msisdn: string, accessToken: string) => {
    const newClient = new TelenorApiClient('https://atest-api.telenor.no/', accessToken, msisdn);
    clients.push(newClient);
    setClients(clients);
  };
  const findClient = (msisdn: string) => {
    return clients.find((it) => it.msisdn === msisdn);
  };
  return [findClient, createNewClient] as const;
};

export type AuthenticatedUserAttributes = {
  email: string;
  email_verified: boolean;
  sub: string;
};

const App: React.FC = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<AuthenticatedUserAttributes>();

  function getAuthenticatedUser() {
    Auth.currentAuthenticatedUser()
      .then((result) => {
        setUser(result.attributes);
      })
      .catch((err) => {
        setUser(undefined);
      });
  }

  Hub.listen('auth', (data) => {
    switch (data.payload.event) {
      case 'signIn':
        getAuthenticatedUser();
        navigate('/');
        break;
      case 'signOut':
        getAuthenticatedUser();
        break;
      default:
        break;
    }
  });

  useEffect(() => {
    getAuthenticatedUser();
  }, []);

  return (
    <ThemeProvider colorMode="system">
      <Routes>
        <Route path="/" element={<TopBar user={user} />}>
          <Route index element={<HomePage user={user} />} />
          <Route path="/accounts/:msisdn?" element={<TestAccountsPage />} />
          <Route path="/login" element={<SignInPage />} />
          <Route path="/flags" element={<FeatureFlagsPage />} />
          <Route path="/tools" element={<ToolsPage />} />
          <Route path="/testconfig" element={<TestConfigPage />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
};

export default App;
